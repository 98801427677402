<template>
<div>

  <div v-show="false">
    <template v-for="(entity, keyE) in $store.state.map.entities">
      <PopupMarkerEntity :entity="entity" :key="keyE"/>
    </template>
  </div>

  <l-map
    ref="map"  
    :options="{ scrollWheelZoom:true, zoomControl: false }"
    :zoom="zoom"  
    :maxZoom="13"
    :minZoom="4"
    :center="mapCenter"  
    id="map-container"
    style="" 
    :style="'height:'+($vuetify.breakpoint.height-menuTopHeight)+'px;'"
  >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>

      <v-marker-cluster ref="markerCluster" :options="clusterOptions"></v-marker-cluster>

      <l-marker color="red" :icon="markerMain" 
                :lat-lng="[lat, lng]"
                ref="rootMarker"
                draggable 
                @dragend="dragend"
                @drag="rootMarkerDrag" >
          <l-popup :open="true" :width="400" class="pa-4 text-center" ref="popup">
            <b>Déplacez l'icone<br>sur votre zone de recherche</b><br>
            {{ cityAddressPopup }}
            <v-btn block color="primary" rounded
                  elevation="0" @click="$root.$emit('fetchMap')"
                  class="mt-4 grey--text text--darken-4">
              <b><v-icon small>mdi-refresh</v-icon> Actualiser les résultats</b>
            </v-btn>
            <div class="text-left">
            <!-- LAT: {{ lat }}<br>LNG: {{ lng }} -->
            </div>
          </l-popup>
      </l-marker>

      <!-- MAIN CIRCLE TO SHOW ROOT RADIUS -->
      <l-circle :lat-lng="[lat, lng]" 
                :radius="rootCircle.radius"
                color="#03a9f4"
                fill-color="#03a9f4"
                :fill-opacity=".35" ref="rootCircle">
      </l-circle>
      

      <v-btn small class="open-list-res-map black--text" color="primary"
              fab style="z-index:1000;" title="Afficher la liste des status"
              @click="$root.$emit('openListMsgPerso')">
        <v-icon >mdi-thought-bubble</v-icon>
      </v-btn>

      <v-btn small class="d-md-none open-list-res-map black--text" color="primary"
              fab style="z-index:1000; top:60px;"
              @click="$root.$emit('openDialogResMap')">
        <v-icon >mdi-menu</v-icon>
      </v-btn>

      <v-btn color="secondary"
            elevation="0" @click="$root.$emit('fetchMap')"
            class="mt-4 btn-tool-map tool-map2">
        <v-icon small>mdi-refresh</v-icon> Actualiser les résultats
      </v-btn>
      <v-btn elevation="0" @click="$root.$emit('closeDialogSearchGeo')"
            class="mt-4 btn-tool-map tool-map1">
        <v-icon small>mdi-format-list-checkbox</v-icon> Afficher la liste
      </v-btn>

  </l-map>

  <div class="map-tools-container-right" v-if="!showListMsgPerso">

    <v-btn v-if="$store.state.auth.user.coordinates.length == 0" 
            to="/step/geoloc" block color="red" class="mb-3">
      <v-icon small>mdi-target</v-icon> Définir ma position
    </v-btn>

    <v-slider v-model="rootRadius" 
              :min="1000" :max="300000" 
              @change="updateRadius"
              color="blue"
              inverse-label class="slider-geo-block"
              :label="parseInt(rootRadius/1000)+' km'"
              :step="1000"
    >
        <template v-slot:prepend>
          <v-icon @click="decrement">mdi-minus</v-icon>
        </template>

        <template v-slot:append>
          <v-icon @click="increment">mdi-plus</v-icon>
        </template>
    </v-slider>


    <v-tabs height="40" style="border-radius:4px;" class="mb-2" centered>
      <v-tab style="min-width: 50px;" @click="setMapMode('all')"><v-icon>mdi-asterisk</v-icon></v-tab>
      <v-tab style="min-width: 50px;" @click="setMapMode('users')"><v-icon>mdi-account</v-icon></v-tab>
      <v-tab style="min-width: 50px;" @click="setMapMode('companies')"><v-icon>mdi-domain</v-icon></v-tab>
      <!-- <v-tab style="min-width: 40px;" @click="setMapMode('housing')"><v-icon>mdi-home-group</v-icon></v-tab> -->
    </v-tabs>

    <v-menu offset-y left v-if="true">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field dense outlined solo elevation="0" 
                      hide-details v-model="searchPlaceStr"
                      class="text-search-geo"
                      placeholder="Rechercher un lieu..."
                      :loading="loadPlaces"
                      v-bind="attrs" v-on="on" 
                      @change="searchPlace()"
                      append-icon="mdi-magnify"
                      @click:append="searchPlace()"
                      style="">
        </v-text-field>
      </template>
      <ListNominatimResult :places="places" :loadPlaces="loadPlaces" :onclick="setNewCenter"/>
    </v-menu>

    <div class="selector-entity-type mt-3">
      <v-btn fab small @click="selectNoEntityType" v-if="mapMode=='companies'"
              class="btn-selector mx-1 d-none d-sm-inline" :color="$store.state.map.entityTypeSelected.length > 0 ? 'white' : 'black'"
              :outlined="$store.state.map.entityTypeSelected.length > 0"
              title="Afficher tout">
          <v-icon>mdi-asterisk</v-icon>
      </v-btn>
      <template v-if="mapMode=='companies'">
        <template v-for="(cat) in $store.state.app.data.companyCategory">
          <v-btn fab small :key="cat._id" @click="selectEntityType(cat._id)"
                class="btn-selector mx-1" :color="cat.iconColor"
                :outlined="$store.state.map.entityTypeSelected.indexOf(cat._id)==-1"
                :title="cat.name">
            <!-- <i :class="'fa fa-'+cat.iconFa"></i> -->
            <v-icon>mdi-{{ cat.icon }}</v-icon>
          </v-btn>
        </template>
      </template>
    </div>
  </div>

  <ListMsgPerso :show="showListMsgPerso"/>
</div>
</template>

<style>
  @import "~leaflet/dist/leaflet.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>

<script>

//import axios from "axios"
import core from '../../plugins/core.js'
const config = require('../../config/' + process.env.NODE_ENV)

import L from 'leaflet';
import {LMap, LTileLayer, LMarker, LCircle, LPopup} from 'vue2-leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'

import '@/assets/js/dist/leaflet.awesome-markers.js'
import '@/assets/js/dist/fonts/font-awesome.css'
import '@/assets/js/dist/leaflet.awesome-markers.css'

import '@/assets/css/mainmap.css'

import ListMsgPerso from '@/components/map/ListMsgPerso.vue'
import ListNominatimResult from '@/components/offer/ListNominatimResult.vue'

import PopupMarkerEntity from '@/components/map/PopupMarkerEntity.vue'
//import ScoreBar from '@/components/map/ScoreBar.vue'

import nominatim from '../../services/nominatim.js'


export default {
  name: 'home', 
  components: {
    LMap, LTileLayer, LMarker, LCircle, LPopup, ListNominatimResult,
    'v-marker-cluster' : Vue2LeafletMarkerCluster, ListMsgPerso,
    PopupMarkerEntity, //ScoreBar
  },
  props: [''],
  data: () => ({
    search: "",
    results: 0,
    showMap: false,

    city: "",
    address: "",
    lat: 46.95026224218562, 
    lng: 2.5913274703850764,

    rootMarker: {
      lat: 46.95026224218562,
      lng: 2.5913274703850764,
    },
    rootCircle: {
      radius: 30000
    },
    rootRadius: 30000,

    markerClusterList: [],

    loadPlaces: false,
    places: null,
    cityAddressPopup: "",

    zoom:11,
    mapCenter: L.latLng(46.95026224218562, 2.5913274703850764),
    url : config.mapTilesUrl,
    attribution:'', //&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    popupContent: "ok",

    clusterOptions:  { showCoverageOnHover: false, maxClusterRadius: 30 },
    searchPlaceStr: "",

    menuTopHeight: 64,

    entityTypeSelected: [],

    showDetailsSelector: true,
    detailsSelector: "",

    mapMode: "all",

    showListMsgPerso: false

  }),
  mounted: async function(){
    //if(this.$store.state.auth.user != null)
    //this.init(this.$store.state.auth.user)

    this.$root.$off('invalidateSizeSearchGeoBlock').$on('invalidateSizeSearchGeoBlock', () => { 
      //console.log("on invalidateSizeSearchGeoBlock")
      this.invalidateSize()
    })

    this.$root.$off('showEntityOnMap').$on('showEntityOnMap', (entity) => { 
      this.$refs.map.mapObject.setZoom(15)
      setTimeout(() => {
        this.$refs.map.mapObject.panTo(entity.coordinates)
        setTimeout(() => {
          this.$refs.map.mapObject.invalidateSize()
          this.$refs.map.mapObject.panBy([200, 0])
          this.markerClusterList.forEach((markerItem) => {
            if(markerItem.id == entity._id)
              markerItem.marker.fire('click')
          })
        }, 700)
      }, 300)
    })

    this.$root.$off("openListMsgPerso").$on("openListMsgPerso", () => {
      this.showListMsgPerso = !this.showListMsgPerso
    })
    this.$root.$off("closeListMsgPerso").$on("closeListMsgPerso", () => {
      this.showListMsgPerso = false
    })

    if(this.$vuetify.breakpoint.width > 960) this.showListMsgPerso = true
  },
  methods: {
    async init(user){ //init est appelé par un watcher qui attend que le user soit chargé
      console.log("init MAINMAP")
      setTimeout(()=>{
        if(this.$store.state.app.data.companyCategory.length == 0)
          this.$store.dispatch('app/fetchEntities', { entityType: "companyCategory", sort: { created: 1 } })
        
        this.updateMarkerMap()
      }, 1000)

      if(user.city != null) this.city = user.city
      if(user.address != null ) this.address = user.address

      if(this.$store.state.map.requestParams.coordinates != null){
        //console.log("get coordinate from requestParams")
        this.lat = this.$store.state.map.requestParams.coordinates[0]
        this.lng = this.$store.state.map.requestParams.coordinates[1]
        this.rootRadius = 300000
        this.rootCircle.radius = 300000
      }
      if(user.coordinates != null && user.coordinates != "") {
        //console.log("get coordinate from user")
        this.lat = user.coordinates[0]
        this.lng = user.coordinates[1]
        this.rootRadius = 30000
        this.rootCircle.radius = 30000
      }

      this.updateRadius()
      this.mapCenter = L.latLng(this.lat, this.lng)

      this.$root.$emit("onChangeSearchGeo", { coordinates: [this.lat, this.lng], 
                                              radius: this.rootRadius, 
                                              fetch: true })

      this.showMap = true
      this.invalidateSize()

      this.saveAddress()
    },
    fitBoundRes: function(){
      let newBound = this.$refs.markerCluster.mapObject.getBounds()
      if(newBound._southWest != null)
        this.$refs.map.mapObject.fitBounds(newBound)
      else
        this.$refs.map.mapObject.fitBounds(this.$refs.rootCircle.mapObject.getBounds())
    },
    selectNoEntityType: function(){
      this.$store.dispatch('map/selectNoEntityType')
      this.updateMarkerMap()
      this.fitBoundRes()
    },
    selectEntityType: function(catId){
      this.$store.dispatch('map/selectEntityType', catId)
      this.updateMarkerMap()
      this.fitBoundRes()
    },
    inMapFilterType(entity){
      //console.log("inMapFilterType", entity._id, this.$store.state.auth.user._id)
      return this.$store.state.map.entityTypeSelected.length == 0
          || (entity.dataType == "user"    && entity._id != this.$store.state.auth.user._id) 
          || (entity.dataType == "company" && this.$store.state.map.entityTypeSelected.indexOf(entity.category._id) > -1)
    },
    setMapMode(mode){
      this.mapMode = mode
      this.$store.dispatch('map/selectMapMode', mode)
      this.selectNoEntityType()
      //this.updateMarkerMap()
    },
    updateMarkerMap(){
        console.log("updateMarkerMap", this.$refs.markerCluster)

        if(this.$refs.markerCluster == null) return 

        let mks = this.$refs.markerCluster.mapObject
        if(mks != null) mks.clearLayers()
        this.markerClusterList = []

        this.cntEntityOnMap = 0

        let entities = this.mapMode == "all" 
                     ? this.$store.state.map.entities 
                     : this.$store.state.map.results[this.mapMode].entities

        entities.forEach((entity) => {
          if(entity.coordinates != null && this.inMapFilterType(entity)){
            //this.cntEntityOnMap++
            //console.log("#MAIN updateMarkersEntitys uid:", entity.uid)
            let icon = this.markerIconEntity(entity)
            //if(window.isDev) { console.log("show entity on map", entity.name, icon); }
            let marker = L.marker([entity.coordinates[0], entity.coordinates[1]], { icon: icon })
            marker.on('click', () => {
              this.entityMapPopup = entity
              setTimeout(()=>{
                let popup = document.getElementById("popup-entity-"+entity._id).innerHTML
                marker.bindPopup("<div id='popup"+entity._id+"' style='min-width:300px'>" +
                                    popup +
                                "</div>")
                marker.openPopup()

                let el = document.querySelector(".leaflet-popup-content #btn-show-details-entity-" + entity._id)
                if(el != null) el.addEventListener("click", ()=>{ this.$root.$emit('openDialogEntity', entity) })

                if(entity.dataType == 'user'){
                  let el2 = document.querySelector(".leaflet-popup-content #btn-open-conv-user-" + entity._id)
                  if(el2 != null) el2.addEventListener("click", ()=>{ this.startConversation(entity._id) })
                }else if(entity.dataType == 'company'){
                  let el2 = document.querySelector(".leaflet-popup-content #btn-open-conv-company-" + entity.userOwner._id + '-' + entity._id)
                  if(el2 != null) el2.addEventListener("click", ()=>{ this.startConversation(entity.userOwner._id, entity._id) })
                }

                
              }, 300)
              
            })
            
            mks.addLayer(marker)
            this.markerClusterList.push({ id: entity._id, marker: marker })
          }
        })
    },
    async saveAddress(){
      this.$root.$emit("onChangeSearchGeo", { coordinates: [this.lat, this.lng], 
                                              radius: this.rootRadius, 
                                              fetch: true })
    },
    startConversation(userId, companyId){
      this.$root.$emit('closeDialogEntity')
      let ok = this.$store.dispatch('app/openConv', { userId: userId, companyId: companyId }) 
      if(ok) this.$store.dispatch('auth/refreshUserData') 
    },
    searchPlace: async function(){
      let query = this.searchPlaceStr + " FR"
      this.loadPlaces = true
      this.places = []
      this.showMap = true
      this.invalidateSize()
      nominatim.search(query, (res)=>{
        this.loadPlaces = false
        if(res.error == false){
          this.places = res.data
          this.setNewCenter(this.places[0].lat, this.places[0].lng)
        }
      })
    },
    setNewCenter: function(lat, lng){
      console.log("setNewCenter", lat, lng)
      this.$refs.map.mapObject.invalidateSize();

      lat = parseFloat(lat)
      lng = parseFloat(lng)
      this.mapCenter = [lat, lng]
      this.lat = lat
      this.lng = lng
      this.updateRadius()
      //this.zoom = 14
      
      setTimeout(() => {
        this.$refs.map.mapObject.invalidateSize()
      }, 100)
      setTimeout(() => {
        //this.$refs.marker.mapObject.openPopup()
        this.$refs.map.mapObject.invalidateSize()
      }, 500)
      
    },
    dragend(e){ 
      e.target.openPopup()
      this.lat = e.target.getLatLng().lat
      this.lng = e.target.getLatLng().lng

      this.saveAddress()
    },
    rootMarkerDrag: function(){
      this.lat = this.$refs.rootMarker.mapObject._latlng.lat
      this.lng = this.$refs.rootMarker.mapObject._latlng.lng
    },
    invalidateSize(){ 
        setTimeout(() => { 
          this.$refs.map.mapObject.invalidateSize()
          this.$refs.rootMarker.mapObject.openPopup()
        }, 400) 
    },

    decrement: function(){
      if(this.rootRadius <= 1000) return
      this.rootRadius -= 1000
      this.updateRadius(false)
    },
    increment: function(){
      this.rootRadius += 1000
      this.updateRadius(false)
    },
    updateRadius: async function(loadContent=true){
      console.log("updateRadius", loadContent)
      this.rootCircle.radius = this.rootRadius
      var tCircle = L.circle(L.latLng(this.lat, this.lng), { radius: this.rootCircle.radius })
      tCircle.addTo(this.$refs.map.mapObject)
      this.$refs.map.mapObject.fitBounds(tCircle.getBounds())
      tCircle.remove()
      this.$refs.rootMarker.mapObject.openPopup()
      this.$root.$emit("onChangeSearchGeo", { coordinates: [this.lat, this.lng], radius: this.rootRadius })
    },
    monyToEuro(amount){ return amount * config.monyToEuro },
    nl2br: function(str, is_xhtml){
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'
      let newStr = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
      let maxLength = 40
      let p = newStr.length > maxLength ? '...' : ''
      newStr = newStr.substr(0, maxLength) + p
      return newStr
    },
    showDialogDetails(res) { 
      this.$root.$emit("openDialogDetails", res)
    },
    baseUrl(){ return core.baseUrl() },
    markerIconEntity: function(entity){
        //let avatar = core.avatarUrl(page.uid) //'markers/icon-popup.png'
        let color = "blue"
        let icon = "point"

        if(entity.dataType != null){
          color = this.$store.state.map.dataTypeConfigDefault[entity.dataType].color
          icon = this.$store.state.map.dataTypeConfigDefault[entity.dataType].icon
        }
        if(entity.dataType == "company"){
          color = entity.category.iconFaColor
          icon = entity.category.iconFa
        }
        if(entity.dataType == "product" && entity.category == "housing"){
          color = "darkblue"
          icon = "home"
        }

        return L.AwesomeMarkers.icon({
                  icon: icon,
                  prefix: 'fa',
                  markerColor: color
              })
    },
    calcTopBarHeight(){
      let el = document.getElementById('top-bar')
      this.menuTopHeight = el ? el.offsetHeight : 64
    },
  },
  computed: {
    markerMain(){ 
      let color = "icon-boussole"
      return L.icon({
        iconUrl: '/marker/'+color+'.png',
        shadowUrl: '/marker/marker-shadow.png',
        iconSize:     [60, 60], // size of the icon
            iconAnchor:   [30, 30], // point of the icon which will correspond to marker's location
            popupAnchor:  [0, -30] // point from which the po
        // shadowSize: [50, 50]
      });
    },
    marker(){ 
      let color = "orange"
      return L.icon({
        iconUrl: '/marker/marker-'+color+'.png',
        shadowUrl: '/marker/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
    },
    
  },
  
  watch: {
    
      '$store.state.map.requestParams.coordinates': { immediate: true, 
          async handler () {
            // if(this.$store.state.map.requestParams.coordinates == null) return
            // let lat = this.$store.state.map.requestParams.coordinates[0]
            // let lng = this.$store.state.map.requestParams.coordinates[1]
            // this.setNewCenter(lat, lng)
          }
      },
      '$store.state.auth.user._id': { immediate: true, 
          async handler () {
              
            if(this.$store.state.auth.isLogin){
              console.log("user logged !", this.$store.state.auth.user)
              setTimeout(()=>{
                this.init(this.$store.state.auth.user)
              }, 100)
            }
          }
      },
      '$store.state.map.entities': { immediate: true, 
          async handler () {
            console.log("$store.state.map.entities changed")
              this.updateMarkerMap()
          }
      },
      '$vuetify.breakpoint.width': { immediate: true, 
          async handler () {
              this.calcTopBarHeight()
          }
      },
      '$vuetify.breakpoint.height': { immediate: true, 
          async handler () {
              this.calcTopBarHeight()
          }
      },
  }, 
}
</script>