<template>
<div class="list-msg-perso pa-2" v-if="show">
  <v-btn x-small color="primary" class="btn-close-bar black--text" 
         fab @click="$root.$emit('closeListMsgPerso')">
    <v-icon color="">mdi-close</v-icon>
  </v-btn>
  <v-btn x-small color="" :loading="$store.state.map.streamLoading" 
        class="btn-close-bar btn-refresh-bar black--text" style=""
         fab @click="$root.$emit('fetchMap')">
    <v-icon color="primary">mdi-refresh</v-icon>
  </v-btn>

  <v-card-title class="primary--text pt-2">
    <v-icon color="primary">mdi-thought-bubble-outline</v-icon>
    Liste des status
  </v-card-title>

  <v-btn rounded block color="primary" class="black--text mb-4" @click="$root.$emit('openDialogEditStatus')">
    <v-icon color="black">mdi-thought-bubble</v-icon>
    <v-icon small color="black">mdi-pencil</v-icon>  Editer mon status
  </v-btn>
  
  <template v-for="(entity, k) in mapEntities">
    <Post :key="k" :entity="entity"/>
  </template>

  <v-card class="pa-3" v-if="streamLength == 0 && !$store.state.map.streamLoading">
    <v-card-title><v-icon>mdi-cancel</v-icon> Aucun status à afficher dans cette zone</v-card-title>
    <v-card-subtitle>Déplacez votre zone de recherche pour plus de résultats...</v-card-subtitle>
  </v-card>
  

</div>
</template>

<style scoped>
  body, html{
    overflow-y: hidden;
  }
  .btn-close-bar{
    position:fixed;
    top:78px;
    right:85px;
    z-index: 3;
  }
  .btn-refresh-bar{
    right:130px;
  }
    
  .list-msg-perso{
    -webkit-animation: slide 0.1s forwards;
    -webkit-animation-delay: 0.1s;
    animation: slide 0.1s forwards;
    animation-delay: 0.1s;
    background-color:rgba(0, 0, 0, 0.8);
    position:absolute;
    top:0px;
    right:-500px;
    width:500px;
    bottom:0px;
    max-width:100%;
    z-index:8;
    overflow: auto;
  }

  @-webkit-keyframes slide {
      100% { right: 0; }
  }

  @keyframes slide {
      100% { right: 0; }
  }

  @media (max-width: 1263px) {
    .list-msg-perso{
      width:450px;
    }
    .btn-close-bar{
      top:75px;
      right:30px;
    }
    .btn-refresh-bar{
      right:75px;
    }
  }
  @media (max-width: 960px) {
    .list-msg-perso{
      width:400px;
      z-index:2;
      padding-bottom:100px !important;
    }
  }
  @media (max-width: 460px) {
    .list-msg-perso{
      width:100%;
    }
  }
  @media (max-width: 600px) {
    
  }

</style>

<script>

//import axios from 'axios'
//import router from '../router/router'
import core from '../../plugins/core.js'

import Post from '@/components/stream/Post.vue'
  

export default {
  name: 'empty',
  props: ['show'],
  components: { Post },
  data: () => ({

  }),
  async mounted(){
    
  },
  methods: {
    
    nl2br: function(str){
      return core.completeText(str, null)
    },

  },
  computed: {
    streamLength(){ return this.$store.state.map.entities.filter((entity) => entity.currentMsg != "" && entity.currentMsg != null) },
    mapEntities(){
      let mapEntities = this.$store.state.map.entities
      mapEntities.forEach((entity, x) => {
        if(entity.dataType == "company")
        mapEntities[x].currentMsg = entity.description
      }) 
      return mapEntities
    }
  }
}
</script>
