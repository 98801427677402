<template>
<div :id="'popup-entity-'+entity._id">
  <v-list id="popup-custom-content" v-if="entity != null">
    <v-list-item class="pl-3 pr-1">
        <v-list-item-avatar height="40" width="40"
            :class="'mr-3'">
          <v-gravatar v-if="!entity.planPaid" height="25" style="border-radius:50%; margin-bottom:-6px;"
                      :default-img="'retro'" :email="entity.name"/>
          <img v-else :src="baseUrl() + entity.imgBadgeCoin" width="42"/>
        </v-list-item-avatar>
        
        <v-list-item-content>

          <v-list-item-title class="headline mb-1" style="line-height: 1.3rem;">
            <b href="javascript:" text 
              style="text-transform:none;font-size:0.7em!important;text-decoration:none;white-space:normal;display:inline;" 
                  :class="'pa-0'">
                  <v-icon v-if="entity.dataType=='user'"  style="margin-bottom:3px;margin-right:-5px;margin-left:-4px;">mdi-account</v-icon>
                  <v-icon v-if="entity.dataType=='company'" :color="entity.category.iconColor" small 
                          style="margin-bottom:3px;margin-right:-1px;">
                          mdi-{{ entity.category.icon }}
                  </v-icon>

                  {{ entity.name }}
            </b>
          </v-list-item-title>
          
          <v-list-item-subtitle class="mb-1" v-if="entity.category!=null">
            <v-icon :color="entity.ownModel.iconColor" small 
                    style="margin-bottom:3px;margin-right:-1px;">
                    mdi-{{ entity.iconFa }}
            </v-icon>
            {{ entity.ownModel.name }}
          </v-list-item-subtitle>

          <v-list-item-subtitle v-show="entity.address!=null ||  entity.city!=null">
            <v-icon small style="margin-bottom:3px;margin-left:2px;">mdi-map-marker</v-icon>
            {{ entity.address }} {{ entity.city }}
            <template v-if="$store.state.auth.user.coordinates.length > 0">
              à {{ getDistance() }} km
            </template>
          </v-list-item-subtitle>

        </v-list-item-content>

        <v-list-item-avatar height="30" width="30" class="mb-3 mt-1 mr-1" v-if="entity.planPaid">
          <v-gravatar height="30" style="border-radius:50%;"
                      :default-img="'retro'" class="" :email="entity.name"/>
        </v-list-item-avatar>

    </v-list-item>

    <v-row class="pa-2 px-4" v-if="entity.currentMsg != null && entity.currentMsg != ''">
      <v-col cols="12" class="py-1 px-1" :class="entity.dataType=='company' ? 'font-papyrus' : ''">
        <v-card class="pa-3" outlined elevation="0" max-height="153" style="overflow:auto;"
                v-html="nl2br(entity.currentMsg)">
        
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row class="pa-2 px-4" v-if="entity.description != null && entity.description != ''">
      <v-col cols="12" class="py-1 px-1">
        <v-card class="pa-3 font-papyrus" outlined elevation="0" max-height="153" style="overflow:auto;"
                v-html="nl2br(entity.description)">
        
        </v-card>
      </v-col>
    </v-row> -->
    <v-row class="pa-2 px-4" v-if="entity._id != $store.state.auth.user._id">
      <v-col cols="6" class="py-1 px-1">
        <v-btn block class="" elevation="0" :id="'btn-show-details-entity-' + entity._id">
          <v-icon class="mr-1" small>mdi-cards-variant</v-icon> Profil
        </v-btn>
      </v-col>
      <v-col cols="6" class="py-1 px-1" v-if="entity.dataType=='user'">
        <v-btn block class="" elevation="0" :id="'btn-open-conv-user-' + entity._id">
          <v-icon class="mr-1" small>mdi-message</v-icon> Contacter
        </v-btn>
      </v-col>
      <v-col cols="6" class="py-1 px-1" v-if="entity.dataType=='company'">
        <v-btn block class="" elevation="0" :disabled="entity.userOwner._id == $store.state.auth.user._id"
              :id="'btn-open-conv-company-' + entity.userOwner._id + '-' + entity._id">
          <v-icon class="mr-1" small>mdi-message</v-icon> Contacter
        </v-btn>
      </v-col>
    </v-row>

  </v-list>

</div>
</template>


<script>

import core from '../../plugins/core.js'
import Gravatar from 'vue-gravatar'

export default {
  components: {
    'v-gravatar' : Gravatar,
  },
  props: ['entity'],

  methods: {
    baseUrl(){ return core.baseUrl() },
    getDistance(){
      return core.getDistance(this.$store.state.auth.user.coordinates, this.entity.coordinates)
    },
    nl2br: function(str, is_xhtml){
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'
      let newStr = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
      return newStr
    },
  },

  computed: {
    nbEmployeeNeeded(){
      //console.log("nbEmployeeNeeded", this.entity.employees.length)
      if(this.entity == null) return 0
      return (this.entity.surface / this.entity.surfaceParcelle * this.entity.nbEmployeesByParcelle) - (this.entity.employees.length + 1) 
    }, 
  },

}

</script>