<template>
<div>

  <v-text-field outlined dense placeholder="Rechercher..." 
                @keypress.enter.exact.prevent="fetchMap()" style="margin: -3px 0 11px 0 !important;"
                hide-details="" v-model="search"></v-text-field>

  <v-btn  block color="secondary" rounded elevation="0" @click="fetchMap()" 
          class="mt-2" :loading="$store.state.map.streamLoading">
    <v-icon small>mdi-refresh</v-icon> Actualiser les résultats
  </v-btn>



  <v-dialog v-model="showPopupCategory" transition="dialog-bottom-transition" :overflowed="true"
            max-width="400" min-width="400" :fullscreen="$vuetify.breakpoint.width < 600"
            content-class="dialog-large-bordered">
    <v-card id="dialog-send-mony">
      <v-toolbar elevation="0">
        <b><v-icon small class="mr-1">mdi-chevron-down</v-icon> Sélectionnez une catégorie</b>
        <v-spacer/>
        <v-btn small outlined fab @click="showPopupCategory = false"><v-icon small>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-divider/>
      <v-col cols="12" class="px-5">
        <v-btn small class="d-inline-flex mt-5" block outlined color=""
                @click="selectCategoryPopup(null)" 
                hide-details>
                <v-icon small>mdi-close</v-icon>
                Aucune catégorie
        </v-btn>

        <v-list class="mt-5" color="transparent" rounded>
          <v-list-item-group v-model="categoryId" @change="fetchMap()">
            <v-list-item  v-for="(category, c) in $store.state.app.data.userCategory" 
                          style="font-size:15px; min-height:40px;"
                          @click="selectCategoryPopup(category._id)"
                          :color="categoryId == category._id ? 'indigo' : ''" :value="category._id" :key="c">
              {{ category.name }}
            </v-list-item>
          </v-list-item-group>
        </v-list>

      </v-col>
    </v-card>
  </v-dialog>
</div>
</template>


<script>

// import axios from "axios"

export default {
  name: 'home',
  components: {
  },
  data: () => ({
    userType: "USER",
    search: "",
    categoryId: null,
    showPopupCategory: false,
    coordinates: [46.95026224218562, 2.5913274703850764],
    radius: 100000,
    showDialogGeoSearch: false
  }),
  mounted: async function(){

    //if(this.$store.state.auth.isLogin)
    this.coordinates = this.$store.state.auth.user.coordinates
      
    this.$root.$off('onChangeSearchGeo').$on('onChangeSearchGeo', async (geoParams) => { 
      //console.log("on onChangeSearchGeo", geoParams)
      this.coordinates = geoParams.coordinates
      this.radius = geoParams.radius

      let params = this.$store.state.map.requestParams 
      params.coordinates = this.coordinates 
      params.radius = this.radius

      await this.$store.dispatch("map/setRequestParams", params)

      //this.initData()
    })

    this.$root.$off('fetchMap').$on('fetchMap', (text, skip) => { 
      //console.log("searchBlock on fetchMap", text)
      if(text != null) this.search = text 
      this.fetchMap(skip)
    })

    this.$root.$off('setTypeSearch').$on('setTypeSearch', (type) => { 
      console.log("on setTypeSearch", type)
      this.setTypeSearch(type)
    })

    this.$root.$off('closeDialogSearchGeo').$on('closeDialogSearchGeo', () => { 
      console.log("on closeDialogSearchGeo")
      this.showDialogGeoSearch = false
    })

    if(this.$store.state.map.results.companies.entities.length == 0
    && this.$store.state.map.results.users.entities.length == 0)
      this.initData()
  },
  methods: {
    async initData(){
      console.log("initData", this.$store.state.map.results.companies.entities.length, this.$store.state.map.results.users.entities.length)
      this.userType = this.$store.state.map.requestParams.userType
      this.search = this.$store.state.map.requestParams.search
      this.coordinates = this.$store.state.map.requestParams.coordinates
      this.radius = this.$store.state.map.requestParams.radius
      this.categoryId = this.$store.state.map.requestParams.categoryId
      this.fetchLimit = this.$store.state.map.requestParams.fetchLimit
      this.skip = 0

      setTimeout(()=>{ this.fetchMap() }, 1000)
    },
    setTypeSearch(type){
      if(type != null) this.userType = type 
      this.fetchMap()
    },
    async fetchMap(skip=0){
      //console.log("searchBlock fetchMap", this.type, this.amountMin, this.amountMax)
      //this.showDialogGeoSearch = false
      this.$store.dispatch('map/fetchMap', {  //userType: this.userType,
                            search: this.$store.state.map.requestParams.search,
                            coordinates: this.$store.state.map.requestParams.coordinates,
                            radius: this.$store.state.map.requestParams.radius,
                            categoryId: this.$store.state.map.requestParams.categoryId,
                            fetchLimit:  this.$store.state.map.requestParams.fetchLimit,
                            skip:  skip
      })
    },

    openDialogGeoSearch(){
      this.showDialogGeoSearch = true
      this.$root.$emit("invalidateSizeSearchGeoBlock")
    },

    selectCategoryPopup(categoryId){
      this.categoryId = categoryId
      this.fetchMap()
      this.showPopupCategory = false
    },

    decrementMax(){ if(this.amountMax > 0) this.amountMax = this.amountMax - 1 },
    decrementMin(){ if(this.amountMax > 0) this.amountMin = this.amountMin - 1 },

    incrementMax(){ this.amountMax = this.amountMax + 1 },
    incrementMin(){ this.amountMin = this.amountMin + 1 },


    keydownAmountMax(event){
      if(event.key.length > 1 || this.amountMax == null) return

      let amount = this.amountMax + ""
      let ascii = event.key.charCodeAt(0)
      if((ascii < 48 || ascii > 57) //si ce n'est pas un nombre
      && (event.key != "." || amount.indexOf(".") > -1) //et que ce n'est pas un point, ni un 2eme point
      || amount.length >= parseFloat(amount).toFixed(4).length
      || amount.length >= 6) { //ou que le nombre de chiffre apres la virgule est de max 4
          event.preventDefault() //annule la frappe au clavier
      }
    },
    keydownAmountMin(event){
      if(event.key.length > 1 || this.amountMin == null) return

      let amount = this.amountMax + ""
      let ascii = event.key.charCodeAt(0)
      if((ascii < 48 || ascii > 57) //si ce n'est pas un nombre
      && (event.key != "." || amount.indexOf(".") > -1) //et que ce n'est pas un point, ni un 2eme point
      || amount.length >= parseFloat(amount).toFixed(4).length
      || amount.length >= 6) { //ou que le nombre de chiffre apres la virgule est de max 4
          event.preventDefault() //annule la frappe au clavier
      }
    },
  },
  computed: {
  },
  watch: {
      
      'search': { immediate: true, 
          async handler () {
            let params = this.$store.state.map.requestParams
            params.search = this.search 
            this.$store.dispatch('map/setRequestParams', params)
          }
      },
  }, 
}
</script>
