<template>
<v-dialog  :fullscreen="$vuetify.breakpoint.width < 960"
            v-model="showDialogRes" max-width="800"
  >
  <v-card id="dialogCaptcha">
    <v-toolbar elevation="1">
      <v-icon small class="mr-1">mdi-magnify</v-icon> Rechercher sur la carte
      <v-spacer/>
      <v-btn @click="showDialogRes = false" 
              icon small class="mr-0">
              <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-col cols="12">
      <ListResMap></ListResMap>
    </v-col>

  </v-card>
</v-dialog>
</template>

<style></style>

<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

import ListResMap from '@/components/map/ListResMap.vue'

export default {
  name: 'empty',
  components: { ListResMap },
  data: () => ({
    showDialogRes: false,
    
  }),
  async mounted(){
    
    this.$root.$off('openDialogResMap')
              .$on('openDialogResMap', async () => { 
      //console.log("on openDialogResMap")
      this.showDialogRes = true
    })
    this.$root.$off('closeDialogResMap')
              .$on('closeDialogResMap', async () => { 
      //console.log("on closeDialogResMap")
      this.showDialogRes = false
    })
  },
  methods: {
    

  },
  computed: {
    
    
  }
}
</script>
